<template>
  <h3 class="bh-text bh-text-left">Surveys > <strong>Survey title</strong></h3>
  <div class="survey-questions">
    <div
      class="bh-card mb-3"
      v-for="(question, index) in filteredSurvey"
      :key="index"
    >
      <p class="bh-text bh-text-left">{{ question.question }}</p>
      <div class="bh-card-secondary">
        <div
          class="close-question-sumary"
          v-if="
            question.type == 'radio' ||
            question.type == 'checkbox' ||
            question.type == 'select'
          "
        >
          <div
            class="row mr-0 ml-0"
            v-for="(element, elementIndex) in question.elements"
            :key="elementIndex"
          >
            <div class="col-2 open-question-number">
              <div>Answer {{ elementIndex + 1 }}</div>
            </div>
            <div class="col-8">
              <div class="close-question-chart">
                <div
                  class="close-question-bar"
                  :style="
                    '--bar-value: ' +
                    GetAnswerPercentage(element.value, question._id) +
                    '%'
                  "
                ></div>
                <div
                  class="close-question-unanswered-bar"
                  :style="
                    '--bar-value: ' +
                    GetAnswerPercentage(element.value, question._id) +
                    '%'
                  "
                ></div>
              </div>
            </div>
            <div class="col-2">
              {{
                GetAnswerCount(element.value, question._id) +
                " (" +
                GetAnswerPercentage(element.value, question._id) +
                "%)"
              }}
            </div>
          </div>
        </div>
        <div
          class="open-question-sumary"
          v-if="
            question.type == 'text' ||
            question.type == 'number' ||
            question.type == 'range' ||
            question.type == 'date'
          "
        >
          <div
            class="row ml-0 mr-0 mt-4"
            v-for="(element, elementIndex) in GetOpenAnswers(question._id)"
            :key="elementIndex"
          >
            <div class="col-3 open-question-number">
              Answer {{ elementIndex + 1 }}
            </div>
            <div class="col-9">
              <div class="open-question-answer">
                {{ element.value }}
              </div>
            </div>
            <div class="col-12">
              <hr style="margin-bottom: 0" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="survey-options bh-text-left">
    <div class="row m-0">
      <div class="col-12">
        <p>Title</p>
        <p class="bh-text-background mb-3">{{ survey.name }}</p>
      </div>
      <div class="col-6">
        <p>Creation date</p>
        <p class="bh-text-background mb-3">
          {{ FilterDate(survey.creationDate) }}
        </p>
      </div>
      <div class="col-6">
        <p>Updated at</p>
        <p class="bh-text-background mb-3">
          {{ FilterDate(survey.updatedAt) }}
        </p>
      </div>
      <div class="col-6 mb-3">
        <p>Status</p>
        <select v-model="survey.status" :class="survey.status" v-on:change="UpdateSurvey">
            <option value="Draft">Draft</option>
            <option value="Active">Active</option>
            <option value="Paused">Paused</option>
            <option value="Archived">Archived</option>
          </select>
      </div>
      <div class="col-6 mb-3">
        <p>Creator</p>
        <img
          :src="survey.creatorPicture"
          style="width: 55px; border-radius: 100%"
        />
        {{ survey.creator }}
      </div>
    </div>
  </div>
</template>
<script>
import SurveyService from "@/services/SurveyService.js";
import moment from "moment";

export default {
  data() {
    return {
      survey: {},
      filteredSurvey: {},
      isLoadingPage: true,
    };
  },
  validations: {},
  mounted() {
    this.GetSurvey();
  },
  methods: {
    GetSurvey() {
      let id = this.$route.params.id;

      return SurveyService.GetSurveyById(id)
        .then(async ({ data }) => {
          this.survey = data.response;
          this.FilterSurvey();
        })
        .catch((e) => {
          window.location.href = "/error";
        });
    },
    UpdateSurvey() {
      let id = this.$route.params.id;

      return SurveyService.UpdateSurvey(id, this.survey)
        .then(async ({ data }) => {
          console.log("Updated");
        })
        .catch((e) => {
          this.error = e.response.data.response;
        });
    },
    FilterSurvey() {
      this.filteredSurvey = this.survey.inputs.filter(
        (input) => input.type != "title"
      );
    },
    SetActiveSurvey() {
      let id = this.$route.params.id;

      return SurveyService.SetActiveSurvey(id)
        .then(async ({ data }) => {
          this.GetSurvey();
        })
        .catch((e) => {
          console.log(e);
          window.location.href = "/error";
        });
    },
    GetAnswerCount(value, questionId) {
      let filtered = this.survey.answers.filter(
        (element) => element.value == value && element.questionId == questionId
      );
      return filtered.length;
    },
    GetAnswerPercentage(value, questionId) {
      let filtered = this.survey.answers.filter(
        (element) => element.value == value && element.questionId == questionId
      );
      let questionAnswers = this.survey.answers.filter(
        (element) => element.questionId == questionId
      );
      let percentage = (filtered.length / questionAnswers.length) * 100;
      return Math.trunc(percentage);
    },
    GetOpenAnswers(questionId) {
      let filtered = this.survey.answers.filter(
        (element) => element.questionId == questionId
      );
      return filtered;
    },
    GetResponses(questionId) {
      let filtered = this.survey.answers.filter(
        (element) => element.questionId == questionId
      );

      return filtered.length;
    },
    FilterDate(date) {
      if (date) return moment(String(date)).format("MM/DD/YYYY");
    },
  },
};
</script>
<style scoped>
@import "../../assets/css/Survey.css";
</style>
